import React from "react";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useAuthState } from "../AuthContext";

export default function MasterAdminRoute({ children }: any) {
  const { user, isLoading } = useAuthState();
  if (isLoading) {
    return (
      <div className="text-center mt-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (!user) return <Redirect to="/login" />;
  if (!user.emailVerified) return <Redirect to="/verify-email" />;
  if (!user.isMasterAdmin) return <Redirect to="/" />;
  return <>{children}</>;
}
