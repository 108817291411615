import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import LicenseSubData from "../../../Licenses/models/LicenseSubData";
import Group from "../../../UserGroup/models/Group";
import { useLicenseServices } from "../LicenseServiceContext";

export default function UpdateLicenseNumberModal({
  group,
  price,
  isOpened = false,
  setIsOpened,
  setGroup,
}: {
  group: Group;
  price: any;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setGroup: React.Dispatch<React.SetStateAction<Group | undefined>>;
}) {
  const services = useLicenseServices();
  const [qty, setQty] = useState(group.licensesQty!);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [prorationData, setProrationData] = useState<any>();

  const handleOnChangeLicensesQty = async (qty: number) => {
    try {
      setQty(qty);
      if (group.licenseSubData?.status === "canceled") return;
      setIsLoading(true);

      const proration = await services.previewLicenseSubProration(
        group.stripeCustomerId!,
        group.licenseSubData?.subcriptionId!,
        qty!
      );

      setProrationData(proration);
      setError("");
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const confirmChangeLicensesQty = async () => {
    try {
      setIsLoading(true);
      if (qty! < group.usersWithLicense!.length) {
        setError(
          `You have to unassign the licenses that you want to delete in order to make this change`
        );
        setIsLoading(false);
        return;
      }

      if (prorationData) {
        const licenseData: LicenseSubData = await services.updateLicenseSub(
          group.gid!,
          prorationData.subscription_proration_date,
          group.licenseSubData?.subcriptionId!,
          qty!
        );
        setGroup({
          ...group,
          licenseSubData: licenseData,
          licensesQty: qty,
        });
        setError("");
        setProrationData(null);
        setIsOpened(false);
        setIsLoading(false);
        return;
      }

      const sub = await services.createLicenseSub({
        gid: group!.gid!,
        customerId: group!.stripeCustomerId!,
        qty,
      });

      localStorage.setItem("latestInvoiceId", sub.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        sub?.latest_invoice?.payment_intent?.status
      );

      const licenseData: LicenseSubData = {
        subcriptionId: sub.id,
        productId: sub.items.data[0].price.product,
        status: sub.status,
        currentPeriodStart: sub.current_period_start,
        currentPeriodEnd: sub.current_period_end,
        createdAt: sub.created,
      };
      setGroup({
        ...group,
        licenseSubData: licenseData,
        licensesQty: qty,
      });
      setError("");
      setProrationData(null);
      setIsOpened(false);
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={isOpened}
      onHide={() => {
        setQty(group.licensesQty!);
        setProrationData(null);
        setIsOpened(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update licences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Row className="justify-content-center align-items-center text-muted">
          <Col xs="6">
            <h4>{price.product.name}</h4>
            <p>{price.product.description}</p>
          </Col>
          <Col xs="2">
            <Form.Control
              type="number"
              value={qty}
              onChange={(e) => handleOnChangeLicensesQty(+e.target.value)}
              min="0"
            />
          </Col>
          <Col xs="4" className="text-center text-secondary">
            <h4>
              $
              {prorationData
                ? `${(prorationData.total / 100).toFixed(2)}`
                : `${((price.unit_amount * qty) / 100).toFixed(2)}`}
            </h4>
          </Col>
        </Row>
        {prorationData && (
          <Row className="justify-content-end align-items-center text-muted">
            <Col xs="4" className="text-center">
              <p>
                Next invoice:{" "}
                <b>
                  {format(
                    new Date(prorationData.next_payment_attempt * 1000),
                    "MM/dd/yyyy"
                  )}
                </b>
              </p>
            </Col>
          </Row>
        )}

        <Row className="justify-content-center pt-2">
          <Col xs="12" className="text-right">
            <Button
              size="lg"
              block
              type="submit"
              disabled={isLoading || !qty || qty === group.licensesQty}
              onClick={confirmChangeLicensesQty}
            >
              Buy
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
