import IAuthRepository from "../models/repositories/IAuthRepository";
import fb from "../../firebaseConfig";
import { AuthUser } from "../models/AuthUser";

export default class FirebaseAuthImpl implements IAuthRepository {
  onAuthChanged = (
    // eslint-disable-next-line no-unused-vars
    onUser: (user: AuthUser | null) => void,
    onNotUser: () => void
  ): (() => void) => {
    const unsuscriber = fb.auth().onAuthStateChanged(async (authuser) => {
      if (authuser) {
        onUser(await this.getAuthUserFromFbUser(authuser));
        return;
      }
      onNotUser();
    });

    return () => {
      unsuscriber();
    };
  };

  createUserWithEmailAndPassword = async (user: {
    email: string;
    password: string;
  }): Promise<AuthUser | null> => {
    const credential = await fb
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);
    await credential.user?.sendEmailVerification();
    return this.getAuthUserFromFbUser(credential.user);
  };

  sendEmailVerification = async (): Promise<void> => {
    const user = fb.auth().currentUser;
    return user?.sendEmailVerification();
  };

  sendPasswordResetEmail = async (email: string): Promise<void> => {
    return fb.auth().sendPasswordResetEmail(email);
  };

  updateAuthUser = async (user: {
    displayName?: string;
    photoURL?: string;
  }): Promise<void> => {
    const { currentUser } = fb.auth();
    if (!currentUser) return;
    await currentUser?.updateProfile(user);
  };

  logIn = async (user: {
    email: string;
    password: string;
  }): Promise<AuthUser | null> => {
    const credential = await fb
      .auth()
      .signInWithEmailAndPassword(user.email, user.password);
    return this.getAuthUserFromFbUser(credential.user);
  };

  logOut = async (): Promise<void> => {
    await fb.auth().signOut();
  };

  private getAuthUserFromFbUser = async (
    user: firebase.default.User | null
  ): Promise<AuthUser | null> => {
    if (!user) return null;
    const idTokenResult = await user.getIdTokenResult();
    const { photoURL, displayName, uid, email, emailVerified } = user;
    return {
      uid,
      photoURL,
      displayName,
      email,
      emailVerified,
      isMasterAdmin: !!idTokenResult.claims.admin,
    };
  };
}
