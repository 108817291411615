import React from "react";
import {
  Button,
  Nav,
  Navbar as BootstrapNavbar,
  Spinner,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useAuthServices, useAuthState, useAuthDispatch } from "../AuthContext";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const state = useAuthState();
  const service = useAuthServices();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const handleLogOut = async () => {
    if (!state.user) {
      history.push("/login");
      return;
    }
    try {
      dispatch({ type: "logout" });
      await service.logOut();
      history.push("/login");
    } catch (e) {
      dispatch({ type: "failure", error: e.message });
    }
  };
  return (
    <BootstrapNavbar variant="dark" bg="secondary">
      <BootstrapNavbar.Brand as={Link} to="/">
        <img
          src={logo}
          height="50"
          className="d-inline-block align-top"
          alt="Heed Logo"
        />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Collapse className="justify-content-end">
        <>
          {state.user?.isMasterAdmin && (
            <Nav.Link className="text-light" as={Link} to="/groups">
              Groups
            </Nav.Link>
          )}
          {state.user && (
            <>
              <Nav.Link className="text-light" as={Link} to="/">
                Users
              </Nav.Link>
              <Nav.Link className="text-light" as={Link} to="/licenses">
                License
              </Nav.Link>
            </>
          )}

          <Button
            variant="outline-light"
            disabled={state.isLoading}
            onClick={handleLogOut}
          >
            {state.user ? "Logout " : "Login "}
            {state.isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
