import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import { useAuthState } from "../../AuthContext";
import { useUserGroupServices } from "../../UserList/UserGroupServiceContext";
import PaymentForm from "../components/PaymentForm";
import { useLicenseServices } from "../LicenseServiceContext";
import LicensesSummary from "../components/LicensesSummary";
import PaymentMethodList from "../components/PaymentMethodList";
import PaymentHistoryList from "../components/PaymentHistoryList";
import LicensesAccions from "../components/LicensesAccions";

const stripePromise = loadStripe(process.env.REACT_APP_PK_STRIPE!);
const LicenseInfo = () => {
  const { user: authUser } = useAuthState();
  const groupServices = useUserGroupServices();
  const licenseServices = useLicenseServices();
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState<any>();
  const [group, setGroup] = useState<Group>();
  const [error, setError] = useState();

  const getPrice = async (gid: string) => {
    const price = await licenseServices.getLicensePrice(gid);
    setPrice(price);
  };

  const fetchData = async () => {
    try {
      if (!authUser) return;
      const authGroup = await groupServices.getGroup(authUser.uid);
      if (!authGroup) throw Error("The user doesn't have a group");

      if (!authGroup.stripeCustomerId) {
        const stripeCustomerId = await licenseServices.createCustomer(
          authGroup!.gid!,
          authGroup!.adminEmail!,
          authUser!.uid!
        );
        await getPrice(authGroup!.gid!);
        setGroup({ ...authGroup, stripeCustomerId });
        setIsLoading(false);
        return;
      }

      await getPrice(authGroup!.gid!);
      setGroup(authGroup);
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Container className="mt-3">
        <h2>License Managment</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {isLoading && <Spinner animation="border" variant="primary" />}
        <>
          {(() => {
            if (!group) return <></>;
            if (!group.licenseSubData)
              return (
                <PaymentForm group={group} setGroup={setGroup} price={price} />
              );

            return (
              <Row>
                <Col xs={12} md={7}>
                  <>
                    <LicensesSummary group={group} />
                    <LicensesAccions
                      group={group}
                      setGroup={setGroup}
                      price={price}
                    />
                  </>
                </Col>
                <Col>
                  <>
                    <PaymentMethodList customerId={group.stripeCustomerId!} />
                    <PaymentHistoryList customerId={group.stripeCustomerId!} />
                  </>
                </Col>
              </Row>
            );
          })()}
        </>
      </Container>
    </Elements>
  );
};

export default LicenseInfo;
