import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./app/AuthContext";
import MasterAdminRoute from "./app/components/MasterAdminRoute";
import Navbar from "./app/components/Navbar";
import PrivateRoute from "./app/components/PrivateRoute";
import VerifyEmail from "./app/components/VerifyEmail";
import ForgotPassword from "./app/ForgotPassword/screens/ForgotPassword";
import GroupListScreen from "./app/GroupList/screens/GroupListScreen";
import LicenseServiceProvider from "./app/Licenses/LicenseServiceContext";
import LicenseInfo from "./app/Licenses/screens/LicenseInfo";
import LogIn from "./app/LogIn/screens/LogIn";
import SignUp from "./app/SignUp/screens/SignUp";
import UserListScreen from "./app/UserList/screens/UserListScreen";
import UserGroupServiceProvider from "./app/UserList/UserGroupServiceContext";

function App() {
  return (
    <AuthProvider>
      <UserGroupServiceProvider>
        <div className="App">
          <Router>
            <Navbar />
            <Switch>
              <Route exact path="/signup" component={SignUp} />
              <Route path="/login" component={LogIn} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/verify-email" component={VerifyEmail} />
              <PrivateRoute>
                <Route exact path="/">
                  <UserListScreen />
                </Route>
                <Route exact path="/licenses">
                  <LicenseServiceProvider>
                    <LicenseInfo />
                  </LicenseServiceProvider>
                </Route>
                <MasterAdminRoute>
                  <Route exact path="/groups">
                    <GroupListScreen />
                  </Route>
                  <Route exact path="/group/:gid">
                    <UserListScreen />
                  </Route>
                </MasterAdminRoute>
              </PrivateRoute>
            </Switch>
          </Router>
        </div>
      </UserGroupServiceProvider>
    </AuthProvider>
  );
}

export default App;
