import React, { useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import { useLicenseServices } from "../LicenseServiceContext";

export default function CancelLicenseModal({
  group,
  isOpened = false,
  setIsOpened,
  setGroup,
}: {
  group: Group;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setGroup: React.Dispatch<React.SetStateAction<Group | undefined>>;
}) {
  const services = useLicenseServices();
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelUser = async () => {
    try {
      setisLoading(true);
      await services.cancelLicenseSub(
        group.gid!,
        group.licenseSubData?.subcriptionId!
      );

      setGroup({
        ...group,
        licenseSubData: {
          ...group.licenseSubData,
          status: "canceled",
        },
        licensesQty: 0,
        usersWithLicense: [],
      });
      setIsOpened(false);
      setisLoading(false);
    } catch (e) {
      setError(e.message);
      setisLoading(false);
    }
  };
  return (
    <Modal show={isOpened} onHide={() => setIsOpened(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel licences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        Are you sure you want to permanently cancel all your licenses?
        <br />
        <br />
        <div className="float-right">
          <Button
            variant="outline-dark"
            className="mr-2"
            disabled={isLoading}
            onClick={() => {
              setIsOpened(false);
            }}
          >
            No, Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => {
              handleDelUser();
            }}
          >
            Yes, Delete
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
