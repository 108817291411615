import { AuthUser } from "../Auth/models/AuthUser";

export type AuthAction =
  | { type: "signup" }
  | { type: "login" }
  | { type: "loginSuccess"; user: AuthUser }
  | { type: "logout" }
  | { type: "logoutSuccess" }
  | { type: "failure"; error: string };

export type AuthState = {
  user?: AuthUser;
  isLoading: boolean;
  error?: string;
};
export const initialState: AuthState = {
  isLoading: true,
};

export function AuthReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "signup":
      return { isLoading: true };
    case "login":
      return { isLoading: true };
    case "loginSuccess":
      return { isLoading: false, user: action.user };
    case "logout":
      return { isLoading: true };
    case "logoutSuccess":
      return { isLoading: false };
    case "failure":
      return { ...state, isLoading: false, error: action.error };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
}
