import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import LicenseSubData from "../../../Licenses/models/LicenseSubData";
import Group from "../../../UserGroup/models/Group";
import { useLicenseServices } from "../LicenseServiceContext";

const PaymentForm = ({
  price,
  group,
  setGroup,
}: {
  price: any;
  group: Group;
  setGroup: React.Dispatch<React.SetStateAction<Group | undefined>>;
}) => {
  const services = useLicenseServices();
  const [qty, setQty] = useState(1);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [validationMsgs, setValidationMsgs] = useState<{
    qty: any;
    creditCard: any;
  }>({
    qty: undefined,
    creditCard: undefined,
  });
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: elements!.getElement(CardElement)!,
      });

      if (error || qty < 1) {
        setIsLoading(false);
        setValidationMsgs({
          qty: qty < 1 && "invalid number",
          creditCard: error?.message,
        });
        return;
      }

      const sub = await services.createLicenseSub({
        gid: group!.gid!,
        paymentMethodId: paymentMethod!.id,
        customerId: group!.stripeCustomerId!,
        qty,
      });

      localStorage.setItem("latestInvoiceId", sub.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        sub?.latest_invoice?.payment_intent?.status
      );

      const licenseData: LicenseSubData = {
        subcriptionId: sub.id,
        productId: sub.items.data[0].price.product,
        status: sub.status,
        currentPeriodStart: sub.current_period_start,
        currentPeriodEnd: sub.current_period_end,
        createdAt: sub.created,
      };

      setIsLoading(false);
      setGroup({
        ...group,
        licenseSubData: licenseData,
        licensesQty: qty,
      });
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Card
        className="shadow-sm mx-auto"
        style={{ minWidth: "40rem", width: "30%" }}
      >
        <Card.Header>
          <h3>Buy Licenses</h3>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Row className="justify-content-center align-items-center text-muted">
            <Col xs="6">
              <h4>{price.product.name}</h4>
              <p>{price.product.description}</p>
            </Col>
            <Col xs="2">
              <Form.Control
                type="number"
                min="0"
                value={qty}
                onChange={(e) => setQty(+e.target.value)}
                isInvalid={validationMsgs.qty}
              />
            </Col>
            <Col xs="4" className="text-center text-secondary">
              <h4>${((price.unit_amount * qty) / 100).toFixed(2)}</h4>
            </Col>
          </Row>
          <Row className="justify-content-center pt-5">
            <Col xs="12">
              <Form.Label>Credit card</Form.Label>
              <div
                className={`form-control ${
                  validationMsgs.creditCard && "is-invalid "
                }`}
                style={{ height: "2.4em", paddingTop: ".7em" }}
              >
                <CardElement />
              </div>
              <Form.Control.Feedback type="invalid">
                {validationMsgs.creditCard}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="justify-content-center pt-4">
            <Col xs="12" className="text-right">
              <Button
                size="lg"
                block
                type="submit"
                disabled={isLoading || !stripe || qty === 0}
              >
                Buy ${((price.unit_amount * qty) / 100).toFixed(2)}
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </form>
  );
};

export default PaymentForm;
