import React, { useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import CancelLicenseModal from "./CancelLicenseModal";
import RetryPaymentButton from "./RetryPaymentButton";
import UpdateLicenseNumberModal from "./UpdateLicenseNumberModal";

export default function LicensesAccions({
  group,
  price,
  setGroup,
}: {
  group: Group;
  price: any;
  setGroup: React.Dispatch<React.SetStateAction<Group | undefined>>;
}) {
  const [isOpenConfirmDel, setIsOpenConfirmDel] = useState(false);
  const [isOpenUpdateLince, setIsOpenUpdateLince] = useState(false);
  const [error, setError] = useState<string>();

  return (
    <>
      <CancelLicenseModal
        group={group}
        setGroup={setGroup}
        isOpened={isOpenConfirmDel}
        setIsOpened={setIsOpenConfirmDel}
      />
      <UpdateLicenseNumberModal
        group={group}
        price={price}
        setGroup={setGroup}
        isOpened={isOpenUpdateLince}
        setIsOpened={setIsOpenUpdateLince}
      />
      <Card className="my-3 shadow-sm">
        <Card.Header>
          <h3>Actions</h3>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <RetryPaymentButton
            group={group}
            setError={setError}
            setGroup={setGroup}
          />

          <Button
            className="mr-2"
            onClick={() => {
              setIsOpenUpdateLince(true);
            }}
          >
            Update licenses number
          </Button>
          {group.licenseSubData?.status !== "canceled" && (
            <Button
              className="mr-2"
              variant="outline-dark"
              onClick={() => {
                setIsOpenConfirmDel(true);
              }}
            >
              Cancel licenses
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
