import React from "react";
import { Modal } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import User from "../../../UserGroup/models/User";
import AddUserForm from "../components/AddUserForm";

const AddUserModal = ({
  group,
  isOpened = false,
  setIsOpened,
  handleAddUser,
}: {
  group: Group;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddUser: (newUser: User) => void;
}) => (
  <Modal show={isOpened} onHide={() => setIsOpened(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Add User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddUserForm
        group={group}
        setIsOpened={setIsOpened}
        handleAddUser={handleAddUser}
      />
    </Modal.Body>
  </Modal>
);

export default AddUserModal;
