import React, { useEffect, useState } from "react";
import { Alert, Container, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import { useUserGroupServices } from "../../UserList/UserGroupServiceContext";
import GroupList from "../components/GroupList";

export default function GroupListScreen() {
  const services = useUserGroupServices();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [groups, setGroups] = useState<Group[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groups = await services.getGroupList();
        if (!groups) throw Error("No groups");

        setGroups(groups);
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Container className="mt-3">
        <h2>Group Managment</h2>

        {error && <Alert variant="danger">{error}</Alert>}
        {isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <GroupList groups={groups!} />
        )}
      </Container>
    </>
  );
}
