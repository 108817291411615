import React, { useState } from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { Formik, Form as FromikForm } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useAuthServices } from "../../AuthContext";

const ForgotPasswordForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .max(100, "Email must be less than 100 characters")
      .required("Email is required"),
  });
  const service = useAuthServices();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);

  const handleForgotPassword = async (values: { email: string }) => {
    try {
      setIsLoading(true);
      await service.sendPasswordResetEmail(values.email);
      setError("");
      setSent(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {sent && (
        <Alert variant="success">
          Email was sent, Go to <Link to="/login">Log in</Link>
        </Alert>
      )}
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleForgotPassword}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <FromikForm>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              size="lg"
              className="w-100"
              disabled={isLoading}
            >
              Send email
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </FromikForm>
        )}
      </Formik>
      <div className="text-center mt-3">
        Back to <Link to="/login">Log In</Link>
      </div>
      <div className="text-center">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
