import React, { useState } from "react";
import { Formik, Form as FromikForm } from "formik";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useUserGroupServices } from "../UserGroupServiceContext";
import Group from "../../../UserGroup/models/Group";
import User, { UserRole } from "../../../UserGroup/models/User";

const AddUserForm = ({
  group,
  setIsOpened,
  handleAddUser,
}: {
  group: Group;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddUser: (newUser: User) => void;
}) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First name must have at least 2 characters")
      .max(50, "First name is too long!")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name must have at least 2 characters")
      .max(50, "Last name is too long!")
      .required("Last name is required"),
    email: Yup.string()
      .email("Must be a valid email address")
      .max(100, "Email must be less than 100 characters")
      .required("Email is required"),
    role: Yup.string().required("Role name is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });
  const services = useUserGroupServices();
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState();

  const handleNewUser = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
  }) => {
    try {
      setisLoading(true);

      const newUser = await services.createUser(
        group.gid!,
        {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          role: UserRole[values.role as "ADMIN" | "USER"],
          company: group.company,
        },
        values.password
      );
      handleAddUser(newUser);
      setIsOpened(false);
    } catch (e) {
      setError(e.message);
      setisLoading(false);
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          role: "USER",
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleNewUser}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <FromikForm>
            <Form.Group>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    placeholder="Enter your first name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  {touched.firstName && errors.firstName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    placeholder="Enter your last name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  {touched.lastName && errors.lastName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                custom
                name="role"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                isInvalid={touched.role && !!errors.role}
              >
                <option>USER</option>
                <option>ADMIN</option>
              </Form.Control>
              {touched.role && errors.role ? (
                <Form.Control.Feedback type="invalid">
                  {errors.role}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && errors.password ? (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              size="lg"
              className="w-100"
              disabled={isLoading}
            >
              Add
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </FromikForm>
        )}
      </Formik>
    </>
  );
};

export default AddUserForm;
