import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMinus,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import User from "../../../UserGroup/models/User";
import { useAuthState } from "../../AuthContext";
import Group from "../../../UserGroup/models/Group";

const UserList = ({
  users = [],
  handleOpenModal,
  group,
  handleOpenActionsModal,
}: {
  users: User[];
  handleOpenModal: (selectedUser: User) => void;
  group: Group;
  handleOpenActionsModal: (user: User) => void;
}) => {
  const { user: authUser } = useAuthState();
  const [filterText, setFilterText] = React.useState("");
  const filteredUsers = users.filter(
    (user) =>
      (user.firstName &&
        user.firstName.toLowerCase().includes(filterText?.toLowerCase())) ||
      (user.lastName &&
        user.lastName.toLowerCase().includes(filterText?.toLowerCase())) ||
      (user.email &&
        user.email.toLowerCase().includes(filterText?.toLowerCase()))
  );
  const columns = [
    {
      name: "Name",
      selector: "firstName",
      sortable: true,
      cell: (user: User) => `${user.firstName} ${user.lastName}`,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Role",
      selector: "role",
      sortable: true,
    },
    {
      name: "Has license",
      cell: (user: User) => {
        const hasLicense = group.usersWithLicense?.find(
          (uid) => uid === user.uid
        );
        if (hasLicense)
          return <FontAwesomeIcon icon={faCheck} className="text-success" />;
        return <FontAwesomeIcon icon={faMinus} />;
      },
    },
    {
      name: "Created at",
      selector: "createdAt",
      format: (user: User) => `${format(user.createdAt, "MM/dd/yyyy")}`,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      cell: (user: User) => (
        <>
          <Button
            variant="outline-info mr-2"
            size="sm"
            // eslint-disable-next-line react/destructuring-assignment
            onClick={() => {
              handleOpenActionsModal(user);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button
            variant="outline-danger"
            size="sm"
            // eslint-disable-next-line react/destructuring-assignment
            disabled={authUser!.uid === user.uid}
            onClick={() => {
              handleOpenModal(user);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </>
      ),
    },
  ];
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Row className="justify-content-end w-100">
        <Form.Control
          type="text"
          placeholder="Search"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          style={{ minWidth: "200px", width: "30%" }}
        />
      </Row>
    );
  }, [filterText]);
  return (
    <>
      <DataTable
        columns={columns}
        data={filteredUsers}
        noHeader
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </>
  );
};

export default UserList;
