import firebase from "firebase/app";
import IAuthDBRepository from "../models/repositories/IAuthDBRepository";
import fb from "../../firebaseConfig";
import User, { UserRole, UserStatus } from "../../UserGroup/models/User";

const collection = "users";
export default class FirebaseAuthImpl implements IAuthDBRepository {
  signUp = async (user: {
    uid: string;
    firstName: string;
    email: string;
    lastName: string;
    company: string;
  }): Promise<User> => {
    const newUser: User = {
      uid: user.uid,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      company: user.company,
      status: UserStatus.active,
      role: UserRole.ADMIN,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await fb.firestore().collection(collection).doc(newUser.uid).set(newUser);
    return newUser;
  };
}
