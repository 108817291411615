import React, { useState } from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { Formik, Form as FromikForm } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthServices,
  useAuthState,
} from "../../AuthContext";

const LogInForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .max(100, "Email must be less than 100 characters")
      .required("Email is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });
  const service = useAuthServices();
  const dispatch = useAuthDispatch();
  const { isLoading } = useAuthState();
  const [error, setError] = useState("");
  const history = useHistory();

  const handleLogIn = async (values: { email: string; password: string }) => {
    try {
      dispatch({ type: "login" });
      await service.logIn(values);
      setError("");
      history.push("/");
    } catch (e) {
      setError(e.message);
      dispatch({ type: "failure", error: e.message });
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleLogIn}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <FromikForm>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && errors.password ? (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              size="lg"
              className="w-100"
              disabled={isLoading}
            >
              Log in
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </FromikForm>
        )}
      </Formik>
      <div className="text-center mt-3">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      <div className="text-center">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </>
  );
};

export default LogInForm;
