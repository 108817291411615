export default interface User {
  uid?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  status?: UserStatus;
  role?: UserRole;
  createdAt?: Date | any;
  updatedAt?: Date | any;
}

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export enum UserStatus {
  active = "active",
  inactive = "inactive",
  deleted = "deleted",
}
