import React from "react";
import { Button, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PaymentBanner() {
  return (
    <Navbar bg="primary justify-content-center text-light">
      <div>
        Add licenses to your account
        <Button
          className="ml-3"
          variant="light"
          size="sm"
          as={Link}
          to="/licenses"
        >
          Go!
        </Button>
      </div>
    </Navbar>
  );
}
