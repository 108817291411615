import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useLicenseServices } from "../LicenseServiceContext";

export default function AddCardForm({
  customerId,
  setIsOpened,
  handleAddPaymentMethod,
}: {
  customerId: string;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddPaymentMethod: (paymentMethod: any) => void;
}) {
  const services = useLicenseServices();
  const [validationMsg, setValidationMsg] = useState<string>("");
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: elements!.getElement(CardElement)!,
      });

      if (error) {
        setIsLoading(false);
        setValidationMsg(error.message!);
        return;
      }

      await services.attachPaymentMethod(paymentMethod!.id, customerId);
      handleAddPaymentMethod(paymentMethod);
      setIsLoading(false);
      setIsOpened(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="mb-3">
        <Form.Label>Credit card</Form.Label>
        <div
          className={`form-control ${validationMsg && "is-invalid "}`}
          style={{ height: "2.4em", paddingTop: ".7em" }}
        >
          <CardElement />
        </div>
        <Form.Control.Feedback type="invalid">
          {validationMsg}
        </Form.Control.Feedback>
      </div>
      <Button size="lg" block type="submit" disabled={isLoading || !stripe}>
        Add
      </Button>
    </form>
  );
}
