import React from "react";
import FirestoreGroupImpl from "../../UserGroup/infraestructure/FirestoreGroupImpl";
import FirestoreUserImpl from "../../UserGroup/infraestructure/FirestoreUserImpl";
import UserApiImpl from "../../UserGroup/infraestructure/UserApiImpl";
import IUserGroupService from "../../UserGroup/usecase/contracts/IUserGroupService";
import UserGroupServiceImpl from "../../UserGroup/usecase/UserGroupServiceImpl";

const groupDb = new FirestoreGroupImpl();
const userDb = new FirestoreUserImpl();
const userApi = new UserApiImpl();
const userGroupService = new UserGroupServiceImpl(groupDb, userApi, userDb);
const UserGroupServiceContext = React.createContext<IUserGroupService>(
  userGroupService
);

export function useUserGroupServices() {
  const context = React.useContext(UserGroupServiceContext);
  if (context === undefined) {
    throw new Error(
      "useUserGroupServices must be used within a UserGroupServiceProvider"
    );
  }

  return context;
}

const UserGroupServiceProvider = ({ children }: { children: any }) => {
  return (
    <UserGroupServiceContext.Provider value={userGroupService}>
      {children}
    </UserGroupServiceContext.Provider>
  );
};

export default UserGroupServiceProvider;
