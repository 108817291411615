import Group from "../models/Group";
import IGroupDBRepository from "../models/repositories/IGroupDBRepository";
import IUserApiRepository from "../models/repositories/IUserApiRepository";
import IUserDBRepository from "../models/repositories/IUserDBRepository";
import User from "../models/User";
import IUserGroupService from "./contracts/IUserGroupService";

export default class UserGroupServiceImpl implements IUserGroupService {
  groupDBRepo: IGroupDBRepository;

  userApiRepo: IUserApiRepository;

  userDBRepo: IUserDBRepository;

  constructor(
    groupDBRepo: IGroupDBRepository,
    userApiRepo: IUserApiRepository,
    userDBRepo: IUserDBRepository
  ) {
    this.groupDBRepo = groupDBRepo;
    this.userApiRepo = userApiRepo;
    this.userDBRepo = userDBRepo;
  }

  getGroupList = async (): Promise<Group[] | null> => {
    return this.groupDBRepo.getGroupList();
  };

  getGroup = async (adminUid: string): Promise<Group | null> => {
    return this.groupDBRepo.getGroupByAdminUid(adminUid);
  };

  getGroupById = async (gid: string): Promise<Group | null> => {
    return this.groupDBRepo.getGroupById(gid);
  };

  createGroup = async (adminUid: string): Promise<Group> => {
    const user = await this.userDBRepo.getUserByUid(adminUid);
    return this.groupDBRepo.createGroup(user);
  };

  getGroupUsers = (gid: string): Promise<User[]> => {
    return this.userDBRepo.getUsers(gid);
  };

  createUser = (gid: string, user: User, password: string): Promise<User> => {
    return this.userApiRepo.createUser(gid, user, password);
  };

  deleteUser = (gid: string, uid: string): Promise<void> => {
    return this.userApiRepo.deleteUser(gid, uid);
  };

  assignLicenseToUser = async (gid: string, uid: string): Promise<void> => {
    return this.groupDBRepo.assignLicenseToUser(gid, uid);
  };

  unassignLicenseToUser = async (gid: string, uid: string): Promise<void> => {
    return this.groupDBRepo.unassignLicenseToUser(gid, uid);
  };
}
