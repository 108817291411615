import React from "react";
import { format } from "date-fns";
import { Button, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Group from "../../../UserGroup/models/Group";

export default function GroupList({ groups = [] }: { groups: Group[] }) {
  const history = useHistory();
  const [filterText, setFilterText] = React.useState("");
  const filteredUsers = groups.filter(
    (group) =>
      (group.company &&
        group.company.toLowerCase().includes(filterText?.toLowerCase())) ||
      (group.adminEmail &&
        group.adminEmail.toLowerCase().includes(filterText?.toLowerCase()))
  );
  const columns = [
    {
      name: "Company",
      selector: "company",
      sortable: true,
    },
    {
      name: "Admin email",
      selector: "adminEmail",
      sortable: true,
    },

    {
      name: "User count",
      cell: (group: Group) => group.users?.length,
    },
    {
      name: "Admin count",
      cell: (group: Group) => group.admins?.length,
    },
    {
      name: "Licenses",
      cell: (group: Group) => group.admins?.length,
    },
    {
      name: "Actions",
      button: true,
      cell: (group: Group) => (
        <Button
          variant="outline-info mr-2"
          size="sm"
          onClick={() => {
            history.push(`/group/${group.gid}`);
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
    },
  ];
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Row className="justify-content-end w-100">
        <Form.Control
          type="text"
          placeholder="Search"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          style={{ minWidth: "200px", width: "30%" }}
        />
      </Row>
    );
  }, [filterText]);
  return (
    <>
      <DataTable
        columns={columns}
        data={filteredUsers}
        noHeader
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </>
  );
}
