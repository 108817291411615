import firebase from "firebase/app";
import fb from "../../firebaseConfig";
import Group from "../models/Group";
import IGroupDBRepository from "../models/repositories/IGroupDBRepository";
import User from "../models/User";

const collection = "groups";
export default class FirestoreGroupImpl implements IGroupDBRepository {
  getGroupList = async (): Promise<Group[] | null> => {
    const queryResult = await fb
      .firestore()
      .collection(collection)
      .where("status", "==", "active")
      .get();

    if (queryResult.empty) return null;

    const groups = queryResult.docs.map<Group>((d) => {
      return { ...d.data(), gid: d.id };
    });

    return groups;
  };

  getGroupByAdminUid = async (uid: string): Promise<Group | null> => {
    const queryResult = await fb
      .firestore()
      .collection(collection)
      .where("admins", "array-contains", uid)
      .limit(1)
      .get();

    if (queryResult.empty) return null;

    const doc = queryResult.docs[0];
    const group: Group = { ...doc.data(), gid: doc.id };

    return group;
  };

  getGroupById = async (gid: string): Promise<Group | null> => {
    const doc = await fb.firestore().collection(collection).doc(gid).get();

    const group: Group = { ...doc.data(), gid: doc.id };
    return group;
  };

  createGroup = async (admin: User): Promise<Group> => {
    const group: Group = {
      status: "active",
      admins: [admin.uid || "error"],
      users: [],
      usersWithLicense: [],
      licensesQty: 0,
      adminEmail: admin.email,
      company: admin.company,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const ref = fb.firestore().collection(collection);
    const { id } = await ref.add(group);

    const newGroup: Group = {
      ...group,
      gid: id,
    };

    const newGroupAdminUser: User = {
      ...admin,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await ref.doc(id).collection("users").doc(admin.uid).set(newGroupAdminUser);

    return newGroup;
  };

  assignLicenseToUser = async (gid: string, uid: string): Promise<void> => {
    await fb
      .firestore()
      .collection(collection)
      .doc(gid)
      .update({
        usersWithLicense: firebase.firestore.FieldValue.arrayUnion(uid),
      });
  };

  unassignLicenseToUser = async (gid: string, uid: string): Promise<void> => {
    await fb
      .firestore()
      .collection(collection)
      .doc(gid)
      .update({
        usersWithLicense: firebase.firestore.FieldValue.arrayRemove(uid),
      });
  };
}
