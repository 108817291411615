import React, { useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import User from "../../../UserGroup/models/User";
import { useUserGroupServices } from "../UserGroupServiceContext";

const ConfirmDeleteUserModal = ({
  group,
  userSelected,
  isOpened = false,
  setIsOpened,
  handleDeleteUser,
}: {
  group: Group;
  userSelected: User | undefined;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteUser: (uid: string) => void;
}) => {
  const services = useUserGroupServices();
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelUser = async () => {
    try {
      setisLoading(true);
      await services.deleteUser(group.gid!, userSelected?.uid!);
      handleDeleteUser(userSelected?.uid!);
      setIsOpened(false);
      setisLoading(false);
    } catch (e) {
      setError(e.message);
      setisLoading(false);
    }
  };

  return (
    <Modal show={isOpened} onHide={() => setIsOpened(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Remove User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        Are you sure you want to permanently remove the user{" "}
        <b>{userSelected?.email}</b>
        <br />
        <br />
        <div className="float-right">
          <Button
            variant="outline-dark"
            className="mr-2"
            disabled={isLoading}
            onClick={() => {
              setIsOpened(false);
            }}
          >
            No, Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => {
              handleDelUser();
            }}
          >
            Yes, Delete
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteUserModal;
