import React from "react";
import LicenseApiImpl from "../../Licenses/infraestructure/LicenseApiImpl";
import ILicenseService from "../../Licenses/usecase/contracts/ILicenseService";
import LicenseServiceImpl from "../../Licenses/usecase/LicenseServiceImpl";

const licenseApi = new LicenseApiImpl();
const licenseGroupService = new LicenseServiceImpl(licenseApi);
const UserGroupServiceContext = React.createContext<ILicenseService>(
  licenseGroupService
);

export function useLicenseServices() {
  const context = React.useContext(UserGroupServiceContext);
  if (context === undefined) {
    throw new Error(
      "useUserGroupServices must be used within a UserGroupServiceProvider"
    );
  }

  return context;
}

const LicenseServiceProvider = ({ children }: { children: any }) => {
  return (
    <UserGroupServiceContext.Provider value={licenseGroupService}>
      {children}
    </UserGroupServiceContext.Provider>
  );
};

export default LicenseServiceProvider;
