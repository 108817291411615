import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcStripe,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLicenseServices } from "../LicenseServiceContext";
import AddCardForm from "./AddCardForm";

const PaymentMethodList = ({ customerId }: { customerId: string }) => {
  const licenseServices = useLicenseServices();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [error, setError] = useState("");

  const handleAddPaymentMethod = (paymentMethod: any) => {
    const newPaymentMethods = {
      ...paymentMethods,
      data: [paymentMethod, ...paymentMethods?.data],
    };
    setPaymentMethods(newPaymentMethods);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const methods = await licenseServices.getPaymentMethods(customerId);
        setPaymentMethods(methods);
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerId]);
  return (
    <>
      <Modal show={isOpened} onHide={() => setIsOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add credit card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCardForm
            customerId={customerId}
            setIsOpened={setIsOpened}
            handleAddPaymentMethod={handleAddPaymentMethod}
          />
        </Modal.Body>
      </Modal>
      <Card className="shadow-sm">
        <Card.Header>
          <h3>Payment methods</h3>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {isLoading && (
            <Spinner animation="border" variant="primary" size="sm" />
          )}
          {paymentMethods?.data?.map((method: any, i: number) => (
            <Row
              key={method.id}
              className="border-bottom px-1 py-3 align-items-center"
            >
              <Col xs="auto" className="pr-0">
                <span className="h3">
                  {getIconByCcBrand(method.card.brand)}
                </span>
              </Col>
              <Col>
                <span className="h6">**** {method.card.last4} </span>
                <br />
                <span className="text-muted">
                  {method.card.exp_month}/{method.card.exp_year}
                </span>
              </Col>
              {i === 0 && (
                <Col xs="auto">
                  <Badge variant="info">default</Badge>
                </Col>
              )}
            </Row>
          ))}
          <Row className="pt-3">
            <Col xs="auto">
              <Button variant="success" onClick={() => setIsOpened(true)}>
                Add credit card
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

function getIconByCcBrand(brand: string): JSX.Element {
  switch (brand) {
    case "amex":
      return <FontAwesomeIcon icon={faCcAmex} />;
    case "visa":
      return <FontAwesomeIcon icon={faCcVisa} />;
    case "diners":
      return <FontAwesomeIcon icon={faCcDinersClub} />;
    case "mastercard":
      return <FontAwesomeIcon icon={faCcMastercard} />;
    case "discover":
      return <FontAwesomeIcon icon={faCcDiscover} />;
    default:
      return <FontAwesomeIcon icon={faCcStripe} />;
  }
}

export default PaymentMethodList;
