import React, { useState } from "react";
import { Alert, Button, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthServices, useAuthState } from "../AuthContext";

export default function VerifyEmail() {
  const { user } = useAuthState();
  const service = useAuthServices();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);
  const reSendEmail = async () => {
    try {
      setIsLoading(true);
      await service.reSendVerificationEmail();

      setSent(true);
      setIsLoading(false);
      setError("");
    } catch (e) {
      setError(e.message);
      setSent(false);
      setIsLoading(false);
    }
  };
  return (
    <Container className="mt-3">
      <h2>Verify your email</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {sent && <Alert variant="success">Email was sent</Alert>}
      {user?.emailVerified ? (
        <>
          <p>Your email is confirmated</p>
          <Button as={Link} to="/">
            Go Home
          </Button>
        </>
      ) : (
        <>
          <p>
            A confirmation email has been sent to <b>{user?.email}</b>, Click on
            the confirmation link in the email to activate your account.
          </p>
          <Button onClick={() => window.location.reload()}>
            Reload the page
          </Button>
          <Button
            className="ml-2"
            variant="outline-primary"
            onClick={() => reSendEmail()}
            disabled={isLoading}
          >
            Resend email
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </>
      )}
    </Container>
  );
}
