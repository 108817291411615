import fb from "../../firebaseConfig";
import IUserDBRepository from "../models/repositories/IUserDBRepository";
import User, { UserStatus } from "../models/User";

const groupsCollection = "groups";
const UserCollection = "users";
export default class FirestoreUserImpl implements IUserDBRepository {
  getUsers = async (groupId: string): Promise<User[]> => {
    const queryResult = await fb
      .firestore()
      .collection(groupsCollection)
      .doc(groupId)
      .collection(UserCollection)
      .where("status", "==", UserStatus.active)
      .get();

    if (queryResult.empty) return [];

    const users = queryResult.docs.map((doc) => {
      const data = doc.data();
      const user: User = {
        ...data,
        uid: doc.id,
        createdAt: data.createdAt.toDate(),
        updatedAt: data.updatedAt.toDate(),
      };
      return user;
    });

    return users;
  };

  getUserByUid = async (uid: string): Promise<User> => {
    const doc = await fb.firestore().collection(UserCollection).doc(uid).get();
    return { ...doc.data(), uid: doc.id };
  };
}
