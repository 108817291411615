import IUserApiRepository from "../models/repositories/IUserApiRepository";
import User from "../models/User";

export default class UserApiImpl implements IUserApiRepository {
  createUser = async (
    groupId: string,
    user: User,
    password: string
  ): Promise<User> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${groupId}/user`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ...user, password }),
      }
    );
    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return {
      ...data,
      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
    };
  };

  deleteUser = async (groupId: string, uid: string): Promise<void> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${groupId}/user/${uid}`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }
  };
}
