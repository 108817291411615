import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { useLicenseServices } from "../LicenseServiceContext";

const PaymentHistoryList = ({ customerId }: { customerId: string }) => {
  const licenseServices = useLicenseServices();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentInvoices, setPaymentInvoices] = useState<any>([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const invoices = await licenseServices.getInvoices(customerId);
        setPaymentInvoices(invoices);
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerId]);

  return (
    <Card className="shadow-sm my-3">
      <Card.Header>
        <h3>Payment history</h3>
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {isLoading && (
          <Spinner animation="border" variant="primary" size="sm" />
        )}
        {paymentInvoices?.data?.map((method: any) => {
          const start = new Date(method.period_start * 1000);
          const end = new Date(method.period_end * 1000);
          return (
            <Row
              key={method.id}
              className="border-bottom px-1 py-2 align-items-center"
            >
              <Col xs="auto" className="text-muted">
                {format(start, "MM/dd/yyyy")} - {format(end, "MM/dd/yyyy")}
              </Col>
              <Col className="text-right">
                ${(method.amount_paid / 100).toFixed(2)}
              </Col>
              <Col xs="auto">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() =>
                    window.open(method.hosted_invoice_url, "_blank")
                  }
                >
                  <FontAwesomeIcon icon={faReceipt} />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default PaymentHistoryList;
