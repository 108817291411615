import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Group from "../../../UserGroup/models/Group";
import User from "../../../UserGroup/models/User";
import { useAuthState } from "../../AuthContext";
import PaymentBanner from "../components/PaymentBanner";
import UserList from "../components/UserList";
import { useUserGroupServices } from "../UserGroupServiceContext";
import ActionsUserModal from "./ActionsUserModal";
import AddUserModal from "./AddUserModal";
import ConfirmDeleteUserModal from "./ConfirmDeleteUserModal";

const UserListScreen = () => {
  const { gid } = useParams<any>();
  const { user: authUser } = useAuthState();
  const services = useUserGroupServices();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [group, setGroup] = useState<Group>();
  const [users, setUsers] = useState<User[]>([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);
  const [isActionsUserModalOpen, setIsActionsUserModalOpen] = useState<boolean>(
    false
  );
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [userSelected, setUserSelected] = useState<User>();

  const handleOpenDeleteModal = (user: User) => {
    setUserSelected(user);
    setIsConfirmModalOpened(true);
  };

  const handleOpenActionsModal = (user: User) => {
    setUserSelected(user);
    setIsActionsUserModalOpen(true);
  };

  const handleAddUser = (newUser: User) => {
    const usersClone = [...users];
    usersClone.push(newUser);
    setUsers(usersClone);
  };

  const handleDeleteUser = (uid: string) => {
    const newUsers = users.filter((u) => u.uid !== uid);
    const newUsersWithLicenses = group?.usersWithLicense?.filter(
      (u) => u !== uid
    );
    setUsers(newUsers);
    setGroup({ ...group, usersWithLicense: newUsersWithLicenses });
  };

  const handleAssignLicense = (uid: string) => {
    const newUsersWithLicenses = group?.usersWithLicense?.slice();
    newUsersWithLicenses?.push(uid);
    setGroup({ ...group, usersWithLicense: newUsersWithLicenses });
  };

  const handleUnassignLicense = (uid: string) => {
    const newUsersWithLicenses = group?.usersWithLicense?.filter(
      (u) => u !== uid
    );
    setGroup({ ...group, usersWithLicense: newUsersWithLicenses });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authUser) return;
        const authGroup = gid
          ? await services.getGroupById(gid)
          : await services.getGroup(authUser.uid);

        if (!authGroup) throw Error("The user doesn't have a group");

        setGroup(authGroup);
        setUsers(await services.getGroupUsers(authGroup!.gid!));
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <AddUserModal
        group={group!}
        isOpened={isAddUserModalOpen}
        setIsOpened={setIsAddUserModalOpen}
        handleAddUser={handleAddUser}
      />
      <ActionsUserModal
        user={userSelected!}
        group={group!}
        isOpened={isActionsUserModalOpen}
        setIsOpened={setIsActionsUserModalOpen}
        handleAssignLicense={handleAssignLicense}
        handleUnassignLicense={handleUnassignLicense}
      />
      <ConfirmDeleteUserModal
        group={group!}
        userSelected={userSelected}
        isOpened={isConfirmModalOpened}
        setIsOpened={setIsConfirmModalOpened}
        handleDeleteUser={handleDeleteUser}
      />

      {!gid && !isLoading && group?.licenseSubData?.status !== "active" && (
        <PaymentBanner />
      )}

      <Container className="mt-3">
        <h2>User Managment</h2>

        {error && <Alert variant="danger">{error}</Alert>}
        {isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <Button
              className="float-right mb-2"
              variant="success"
              onClick={() => {
                setIsAddUserModalOpen(true);
              }}
            >
              Add new user
            </Button>
            <UserList
              users={users}
              handleOpenModal={handleOpenDeleteModal}
              group={group!}
              handleOpenActionsModal={handleOpenActionsModal}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default UserListScreen;
