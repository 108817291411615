import ILicenseApiRepository from "../models/repositories/ILicenseApiRepository";

export default class LicenseApiImpl implements ILicenseApiRepository {
  createCustomer = async (
    gid: string,
    email: string,
    uid: string
  ): Promise<string> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${gid}/license/customer`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ email, uid }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data.id;
  };

  getLicensePrice = async (gid: string): Promise<string> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${gid}/license/price`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  createLicenseSub = async ({
    gid,
    paymentMethodId,
    customerId,
    qty,
  }: {
    gid: string;
    paymentMethodId?: string;
    customerId: string;
    qty: number;
  }): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${gid}/license`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ paymentMethodId, customerId, qty }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  getPaymentMethods = async (customerId: string): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${customerId}/payment-method`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  attachPaymentMethod = async (
    paymentMethodId: string,
    customerId: string
  ): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${customerId}/payment-method`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ paymentMethodId }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  getInvoices = async (customerId: string): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${customerId}/invoices`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  getInvoice = async (customerId: string, invoiceId: string): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${customerId}/invoices/${invoiceId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  cancelLicenseSub = async (gid: string, subId: string): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${gid}/license/cancel`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ subId }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };

  previewLicenseSubProration = async (
    customerId: string,
    subscriptionId: string,
    qty: number
  ): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${customerId}/license/prorate/preview`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ subId: subscriptionId, qty }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error);

    return data;
  };

  updateLicenseSub = async (
    groupId: string,
    prorationDate: number,
    subscriptionId: string,
    qty: number
  ): Promise<any> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/group/${groupId}/license/prorate`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ subId: subscriptionId, qty, prorationDate }),
      }
    );

    const { data, error } = await response.json();
    if (!response.ok) throw new Error(error.message);

    return data;
  };
}
