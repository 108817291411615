import React from "react";
import { Container, Card } from "react-bootstrap";
import LogInForm from "../components/LogInForm";

const LogIn = () => {
  return (
    <>
      <Container className="d-flex justify-content-center">
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card className="mt-3 shadow-sm">
            <Card.Body>
              <h2 className="text-center mb-3">Log In</h2>
              <LogInForm />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default LogIn;
