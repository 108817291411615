import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mt-3 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-3">Sign Up</h2>
                <SignUpForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignUp;
