import React, { useEffect, useReducer } from "react";
import FirebaseAuthImpl from "../Auth/infrastructure/FirebaseAuthImpl";
import FirebaseDBAuthImpl from "../Auth/infrastructure/FirebaseDBAuthImpl";
import { AuthUser } from "../Auth/models/AuthUser";
import AuthServiceImpl from "../Auth/usecase/AuthServiceImpl";
import { IAuthService } from "../Auth/usecase/contracts/IAuthService";
import {
  initialState,
  AuthReducer,
  AuthState,
  AuthAction,
} from "./authReducer";

const AuthStateContext = React.createContext<AuthState>({} as AuthState);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthAction>>(
  {} as React.Dispatch<AuthAction>
);

const auth = new FirebaseAuthImpl();
const authDb = new FirebaseDBAuthImpl();
const authService = new AuthServiceImpl(auth, authDb);
const AuthServicesContext = React.createContext<IAuthService>(authService);

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

export function useAuthServices() {
  const context = React.useContext(AuthServicesContext);
  if (context === undefined) {
    throw new Error("useAuthServices must be used within a AuthProvider");
  }

  return context;
}

export const AuthProvider = ({ children }: { children: any }) => {
  const [authState, authDispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const unsubscriber = authService.onAuthChanged(
      (user: AuthUser | null) => {
        if (!user) return;
        authDispatch({ type: "loginSuccess", user });
      },
      () => {
        authDispatch({ type: "logoutSuccess" });
      }
    );

    return unsubscriber;
  }, []);

  return (
    <AuthServicesContext.Provider value={authService}>
      <AuthStateContext.Provider value={authState}>
        <AuthDispatchContext.Provider value={authDispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    </AuthServicesContext.Provider>
  );
};
