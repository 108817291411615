import ILicenseApiRepository from "../models/repositories/ILicenseApiRepository";
import ILicenseService from "./contracts/ILicenseService";

export default class LicenseServiceImpl implements ILicenseService {
  licenseApiRepo: ILicenseApiRepository;

  constructor(licenseApiRepo: ILicenseApiRepository) {
    this.licenseApiRepo = licenseApiRepo;
  }

  createCustomer = (
    gid: string,
    email: string,
    uid: string
  ): Promise<string> => {
    return this.licenseApiRepo.createCustomer(gid, email, uid);
  };

  getLicensePrice = async (gid: string): Promise<string> => {
    return this.licenseApiRepo.getLicensePrice(gid);
  };

  createLicenseSub = ({
    gid,
    paymentMethodId,
    customerId,
    qty,
  }: {
    gid: string;
    paymentMethodId?: string;
    customerId: string;
    qty: number;
  }): Promise<any> => {
    return this.licenseApiRepo.createLicenseSub({
      gid,
      paymentMethodId,
      customerId,
      qty,
    });
  };

  getPaymentMethods = (customerId: string): Promise<any> => {
    return this.licenseApiRepo.getPaymentMethods(customerId);
  };

  attachPaymentMethod = (
    paymentMethodId: string,
    customerId: string
  ): Promise<any> => {
    return this.licenseApiRepo.attachPaymentMethod(paymentMethodId, customerId);
  };

  getInvoice = (customerId: string, invoiceId: string): Promise<any> => {
    return this.licenseApiRepo.getInvoice(customerId, invoiceId);
  };

  getInvoices = (customerId: string): Promise<any> => {
    return this.licenseApiRepo.getInvoices(customerId);
  };

  cancelLicenseSub = (gid: string, subId: string): Promise<any> => {
    return this.licenseApiRepo.cancelLicenseSub(gid, subId);
  };

  previewLicenseSubProration = async (
    customerId: string,
    subscriptionId: string,
    qty: number
  ): Promise<any> => {
    return this.licenseApiRepo.previewLicenseSubProration(
      customerId,
      subscriptionId,
      qty
    );
  };

  updateLicenseSub = async (
    groupId: string,
    prorationDate: number,
    subscriptionId: string,
    qty: number
  ): Promise<any> => {
    return this.licenseApiRepo.updateLicenseSub(
      groupId,
      prorationDate,
      subscriptionId,
      qty
    );
  };
}
