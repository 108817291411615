import React, { useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import User from "../../../UserGroup/models/User";
import { useUserGroupServices } from "../UserGroupServiceContext";

const ActionsUserModal = ({
  user,
  group,
  isOpened = false,
  setIsOpened,
  handleAssignLicense,
  handleUnassignLicense,
}: {
  user: User;
  group: Group;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleAssignLicense: (uid: string) => void;
  handleUnassignLicense: (uid: string) => void;
}) => {
  const services = useUserGroupServices();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const hasLicense =
    user && group?.usersWithLicense?.find((uid) => uid === user.uid);

  const onClickAssign = async () => {
    try {
      if (!group) return;
      if (group?.licensesQty! === group?.usersWithLicense?.length!) {
        setError("You need to buy more licenses or unassign one, to assign it");
        return;
      }
      setIsLoading(true);

      await services.assignLicenseToUser(group.gid!, user.uid!);
      handleAssignLicense(user.uid!);

      setIsOpened(false);
      setError("");
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const onClickUnassign = async () => {
    try {
      if (!group) return;
      setIsLoading(true);

      await services.unassignLicenseToUser(group.gid!, user.uid!);
      handleUnassignLicense(user.uid!);

      setIsOpened(false);
      setError("");
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={isOpened}
      onHide={() => {
        setError("");
        setIsOpened(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Row className="justify-content-center">
          <Col xs="auto">
            {!hasLicense ? (
              <Button disabled={isLoading} onClick={onClickAssign}>
                Assign License
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            ) : (
              <Button
                variant="dark"
                disabled={isLoading}
                onClick={onClickUnassign}
              >
                Unassign License
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ActionsUserModal;
