import React, { useState } from "react";
import { Row, Col, Button, Form, Alert, Spinner } from "react-bootstrap";
import { Formik, Form as FromikForm } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthServices,
  useAuthState,
} from "../../AuthContext";
import { useUserGroupServices } from "../../UserList/UserGroupServiceContext";

const SignUpForm = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First name must have at least 2 characters")
      .max(50, "First name is too long!")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name must have at least 2 characters")
      .max(50, "Last name is too long!")
      .required("Last name is required"),
    email: Yup.string()
      .email("Must be a valid email address")
      .max(100, "Email must be less than 100 characters")
      .required("Email is required"),
    company: Yup.string()
      .min(2, "Company name must have at least 2 characters")
      .max(50, "Company name is too long!")
      .required("Company name is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });
  const authService = useAuthServices();
  const groupService = useUserGroupServices();

  const dispatch = useAuthDispatch();
  const { isLoading } = useAuthState();
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSignUp = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    company: string;
  }) => {
    try {
      dispatch({ type: "signup" });
      const { uid } = await authService.signUp(values);
      await groupService.createGroup(uid);
      setError("");
      history.push("/");
    } catch (e) {
      setError(e.message);
      dispatch({ type: "failure", error: e.message });
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSignUp}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <FromikForm>
            <Form.Group>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    placeholder="Enter your first name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  {touched.firstName && errors.firstName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    placeholder="Enter your last name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  {touched.lastName && errors.lastName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <Form.Control
                placeholder="Enter your company name"
                name="company"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                isInvalid={touched.company && !!errors.company}
              />
              {touched.company && errors.company ? (
                <Form.Control.Feedback type="invalid">
                  {errors.company}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && errors.password ? (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              size="lg"
              className="w-100"
              disabled={isLoading}
            >
              Sign up
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </FromikForm>
        )}
      </Formik>
      <div className="text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  );
};

export default SignUpForm;
