import { AuthUser } from "../models/AuthUser";
import IAuthDBRepository from "../models/repositories/IAuthDBRepository";
import IAuthRepository from "../models/repositories/IAuthRepository";
import { IAuthService } from "./contracts/IAuthService";

export default class AuthServiceImpl implements IAuthService {
  authRepo: IAuthRepository;

  authDBRepo: IAuthDBRepository;

  constructor(authRepo: IAuthRepository, authDBRepo: IAuthDBRepository) {
    this.authRepo = authRepo;
    this.authDBRepo = authDBRepo;
  }

  onAuthChanged = (
    // eslint-disable-next-line no-unused-vars
    onUser: (user: AuthUser | null) => void,
    onNotUser: () => void
  ): (() => void) => {
    return this.authRepo.onAuthChanged(onUser, onNotUser);
  };

  reSendVerificationEmail = (): Promise<void> => {
    return this.authRepo.sendEmailVerification();
  };

  sendPasswordResetEmail = async (email: string): Promise<void> => {
    return this.authRepo.sendPasswordResetEmail(email);
  };

  signUp = async (user: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    company: string;
  }): Promise<AuthUser> => {
    const fbUser = await this.authRepo.createUserWithEmailAndPassword(user);
    if (!fbUser) throw new Error("Failed when try to create auth user");
    await this.authDBRepo.signUp({
      uid: fbUser.uid,
      ...user,
    });
    await this.authRepo.updateAuthUser({
      displayName: `${user.firstName} ${user.lastName}`,
    });
    return fbUser;
  };

  logIn = async (user: {
    email: string;
    password: string;
  }): Promise<AuthUser | null> => {
    const fbUser = await this.authRepo.logIn(user);
    if (!fbUser) throw new Error("Failed when try to authenticate user");

    return fbUser;
  };

  logOut = async (): Promise<void> => {
    await this.authRepo.logOut();
  };
}
