import { useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";
import { useLicenseServices } from "../LicenseServiceContext";

export default function RetryPaymentButton({
  group,
  setError,
  setGroup,
}: {
  group: Group;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setGroup: React.Dispatch<React.SetStateAction<Group | undefined>>;
}) {
  const services = useLicenseServices();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);

  const handleRetryPayment = async () => {
    const latestInvoiceId = localStorage.getItem("latestInvoiceId");
    if (!latestInvoiceId) return;
    setIsLoading(true);
    const invoice = await services.getInvoice(
      group.stripeCustomerId!,
      latestInvoiceId!
    );
    const { data } = await services.getPaymentMethods(group.stripeCustomerId!);

    const { error, paymentIntent } = await stripe!.confirmCardPayment(
      invoice.payment_intent.client_secret,
      { payment_method: data[0].id }
    );

    if (error) {
      setIsLoading(false);
      setError(error!.message);
      return;
    }

    if (paymentIntent!.status === "succeeded") {
      setIsLoading(false);
      setGroup({
        ...group,
        licenseSubData: { ...group.licenseSubData, status: "active" },
      });
    }
  };
  if (group.licenseSubData?.status === "incomplete") {
    return (
      <Button
        className="mr-2"
        disabled={isLoading}
        onClick={() => {
          handleRetryPayment();
        }}
      >
        Retry payment
        {isLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    );
  }
  return <></>;
}
