import React from "react";
import { format } from "date-fns";
import { Badge, Card } from "react-bootstrap";
import Group from "../../../UserGroup/models/Group";

const LicensesSummary = ({ group }: { group: Group }) => {
  const currentPeriodEnd = group.licenseSubData?.currentPeriodEnd;
  return (
    <Card className="shadow-sm">
      <Card.Body>
        <span className="h4 mr-4">
          Total:
          <Badge variant="light ml-1">{group.licensesQty}</Badge>
        </span>
        <span className="h4 mr-4">
          Assigned:
          <Badge variant="secondary ml-1">
            {group.usersWithLicense?.length}
          </Badge>
        </span>
        <span className="h4 mr-4">
          Unassigned:
          <Badge variant="success ml-1">
            {group.licensesQty! - group.usersWithLicense!.length}
          </Badge>
        </span>
        <br />
        <br />
        <span className="h5 mr-4">
          Status:
          {SubStatusBadge(group.licenseSubData?.status)}
        </span>
        <br />
        <div>
          <span className="h5">Next invoice date: </span>
          <span className="text-muted">
            {currentPeriodEnd &&
              format(
                new Date(group.licenseSubData!.currentPeriodEnd! * 1000),
                "MM/dd/yyyy HH:mm"
              )}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

function SubStatusBadge(status: string | undefined): JSX.Element {
  switch (status) {
    case "trialing":
    case "active":
      return <Badge variant="success ml-1">{status}</Badge>;
    case "incomplete":
    case "incomplete_expired":
    case "canceled":
    case "canceleunpaid":
    default:
      return <Badge variant="dark ml-1">{status}</Badge>;
  }
}

export default LicensesSummary;
